#navbar {
  background-color: rgb(20, 20, 20);
  z-index: 1;
  position: relative;
  box-sizing: content-box;
  height: 100px;
  margin-left: 0;
  margin-top: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: column;
  
  button {
    background-color: red;
    color: black;
    flex-grow: 1;
    height: 100%;
    font-weight: 550;
  }
  
  #buttondiv {
    display: flex;
    margin-top: 10px;
    height: 33%;
    width: 100%;
  }
  
  p {
    font-size: 14px;
  }
}
