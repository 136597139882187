#sortDiv {
  position: absolute;
  right: 0;
  top: -20px;
  background-color: black;
  width: 100vw;
  text-align: right;
  left: 0;


  #sortButton {
    background-color: rgba(255, 255, 255, 0);
    color: white;
    border-width: 0;
    font-weight: normal;
    z-index: 1000;
    position: relative;
    cursor: pointer;
  }
  
  #sortButton:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  #sortDropdown {
    position: fixed;
    bottom: 120px;
    background-color: rgb(0, 0, 0);
    width: fit-content;
    margin-bottom: 0;
    padding-top: 1px;
    padding-bottom: 10px;
    padding-left: 7px;
    padding-right: 10px;
    border-width: 2px;
    border-color: white;
    border-style: solid;
    list-style-type: none;
    text-align: left;
    border-radius: 6%;
    right: 10px;
  }
  
  li {
    padding-top: 5px;
    padding-bottom: 5px;
    box-sizing: border-box;
    cursor: pointer;
  }
  
  li:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
  }
}
