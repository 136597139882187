#profile {
  padding-left: 3%;
  overflow-y: auto;
  height: calc(100vh - 125px);
  overflow-y: auto;
  scrollbar-color: red black;  

  #contentPolicyLink {
    display: flex;
    justify-content: end;
    position: absolute;
    width: 100%;
    right: 3%;
    padding: 10px;
    font-size: small;
  }

  h3 {
    margin-bottom: 5px;
  }

  // .refreshIcon {
  //   height: 15px;
  //   width: auto;
  //   position: relative;
  //   left: calc(97vw - 25px);
  //   top: 5px
  // }

  input[type="text"] {
    color: black;
    display: block;
    margin-bottom: 6px;
  }

  input[type="checkbox"] {
    cursor: pointer;
  }

  .twitterScreenName {
    display: flex;
    flex-direction: row;
    justify-content: start;
    background-color: rgb(15, 20, 25);
    width: fit-content;
    color: white;
    align-items: center;
    height: 40px;
    padding-right: 10px;
    padding-left: 10px;

    img {
      height: 25px;
      margin-right: 10px;
    }

    span {
      font-size: 16px;
      font-weight: 550;
    }
  }

  p {
    font-size: small;
  }

  #supportButton {
    margin-top: 20px;
    padding: 15px !important;
    margin-inline: auto;
    font-size: 16px;
    position: relative;
    left: 50%;
    translate: -50%;
    background-color: green;

    &:hover {
      background-color: rgb(0, 190, 0) !important;
    }
  }
}