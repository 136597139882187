#commentSection {

  #commentContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: red black;
    height: calc(100vh - 230px);
    padding-top: 8px;

    .comment {
      padding-top: 2px;
      padding-right: 10px;
      padding-left: 10px;
      padding-bottom: 2px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      
      .commentTop {
        font-weight: 500;
        font-size: 85%;
        color: rgb(205, 202, 202);
        display: flex;
        align-items: end;
        justify-content: space-between;
        margin-bottom: 8px;
        position: relative;
      }

      .commentBottom {
        margin-top: 2px;
        display: flex;
        justify-content: space-between;
      }

      .editTextArea {
        z-index: 1000;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        position: static;
      }

      .editButtons {
        background-color: black;
        border-width: 0px;
        padding: 0px; 
      }

      .commentTime {
        color: rgb(205, 202, 202);
        font-size: 70%;
      }

      .likeButton {
        background-color: rgba(0, 0, 0, 0);
        border-width: 0;
        cursor: pointer;

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      .commentTimestamp {
        color: rgb(0, 149, 255);
        background-color: rgba(0, 0, 0, 0);
        border-width: 0;
        font-size: 75%;
        text-align: start;
        font-weight: 400;
        cursor: pointer;

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      .commentMenuIcon {
        background-color: rgba(0, 0, 0, 0);
        border-width: 0px;
        height: fit-content;
        cursor: pointer;
        padding: 1px 5px 3px;
        transform: translate(5px, -1px);

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }

        img {
          height: 3.5px;
        }

      }
    }
  }


  #postComment {
    bottom: 20px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  textarea {
    width: calc(100vw - 20px);
    min-height: 50px;
    max-height: 150px;
    left: 10px;
    position: relative;
    display: flex;
    box-sizing: border-box;
    align-items: start;
    color: white;
    font-family: "Segoe UI", Tahoma, sans-serif;
    background-color: black;
    resize: none;
    scrollbar-color: white black;
    scrollbar-width: thin;

  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }








  #loadMore {
    min-height: 20px;
    max-height: 20px;
    // margin-bottom: 20px;
    width: fit-content;
    align-self: center;
  }

  load-bar {
    /* margin-bottom: 20px; */
    align-self: center;
  }

  .error {
    z-index: 10;
    color: red;
    background-color: rgb(20, 20, 20);
    position: absolute;
    top: 0;
    left: 0;
  }

}