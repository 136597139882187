.commentMenu {
  position: absolute;
  background-color: black;
  width: fit-content;
  top: -5px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 7px;
  padding-right: 7px;
  border-width: 2px;
  border-color: white;
  border-style: solid;
  list-style-type: none;
  text-align: left;
  border-radius: 6%;
  right: 20px;
  z-index:1000;
  

  li {
    background-color: rgba(255, 255, 255, 0);
    color: white;
    border-width: 0;
    font-weight: normal;
    z-index: 1000;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
    box-sizing: border-box;
    cursor: pointer;
  }
  
  li:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

}
