#auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  height: calc(100vh - 125px);
  overflow-y: auto;
  scrollbar-color: red black; 
  
  #contentPolicyLink {
    display: flex;
    justify-content: end;
    width: 100%;
    padding: 10px;
    font-size: small;
    margin-bottom: -10px;
    text-decoration: underline;

    a {
      width: fit-content
    }
  }

  div {
    width: 80%;
  }
  
  form {
    width: 80%;
    max-width: 300px;
    padding: 3%;
    border-color: white;
    border-width: 2px;
    border-style: solid;
    border-radius: 2.7%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 1;
  }
  
  input {
    color: black;
    display: block;
    margin-bottom: 6px;
    width: 100%;
  }
  
  input[type="checkbox"] {
    display: inline;
    width: 10% !important;
  }

  #submitButtonDiv {
    width: 100%;
  }

  button[type="submit"] {
    width: 100%;
    height: 50px;
    font-size: 19px;
  }

  a {
    width: 100%;
    text-decoration: none;
  }
  
  button {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .supportButton {
    margin-top: 20px;
    padding: 6px !important;
  }
}

