// styles to apply to each component

body {
  background-color: black;
  box-sizing: border-box;
  margin: 0;
  overflow: hidden;
}

* {
  color: white;
}

.redButton {
  background-color: red;
  color: black;
  font-weight: 550;
  cursor: pointer;
  
  &:hover {
    background-color: rgb(255, 41, 41) !important;
  }
}
