#closeButton {
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background-color: rgb(78, 78, 78, 0);
  border-width: 2px;
  border-radius: 100%;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  border-color: white;
  border-style: solid;
  cursor: pointer;
  z-index: 1000;

  &:hover {
    background-color: rgb(0, 0, 0);
    color: red;
    border-color: red;
  }
}